<template>
  <el-dialog
    title="风险驳回"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"

      label-width="120px"
    >
      <el-form-item
        label="驳回原因"
      >
        <el-input
          v-model="form.overruleReason"
          maxlength="255"
          type="textarea"
          placeholder="请输入驳回原因"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { OverruleRisk } from '@/api/risk/risk'

export default {
  data() {
    return {
      users: [],
      loading: false,
      dialogVisible: false,
      form: {
        riskManageId: '',
        overruleReason: '',
      },
    }
  },
  methods: {
    dialogOpen() {

    },
    dialogClose() {
      this.form = {
        riskManageId: '',
        overruleReason: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          this.loading = true
          OverruleRisk(this.form)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
        } else {
          return false
        }
      })
    },
  },
}
</script>
