<template>
  <el-dialog
          title="风险处理"
          :visible.sync="dialogVisible"
          width="620px"
          @close="dialogClose"
          @open="dialogOpen"
  >
    <el-form
            v-if="dialogVisible"
            ref="form"
            v-loading="loading"
            :model="form"

            label-width="120px"
    >
      <el-form-item
              label="处理情况"
      >
        <el-input
            maxlength="255"
                type="textarea"
                v-model="form.disposalResult"
                placeholder="请输入处理情况"
        />
      </el-form-item>
    </el-form>
    <div
            slot="footer"
            class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
              type="primary"
              :disabled="loading"
              @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { success, error } from '@core/utils/utils'
  import { DisposalRisk } from '@/api/risk/risk'
  export default {
    data() {
      return {
        users: [],
        loading: false,
        dialogVisible: false,
        form: {
          riskManageId: '',
          disposalResult: '',
        },
      }
    },
    methods: {
      dialogOpen() {

      },
      dialogClose() {
        this.form = {
          riskManageId: '',
          disposalResult: '',
        }
        this.$refs.form.resetFields()
      },
      submitForm() {
        // eslint-disable-next-line consistent-return
        this.$refs.form.validate(valid => {
          // eslint-disable-next-line no-empty
          if (valid) {
            this.loading = true
            DisposalRisk(this.form).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.dialogVisible = false
                this.$emit('handleFilter')
              } else {
                error(resData.msg)
              }
            })
          } else {
            return false
          }
        })
      },
    },
  }
</script>
