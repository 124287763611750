import { render, staticRenderFns } from "./RiskAssign.vue?vue&type=template&id=29e2f6ca&scoped=true"
import script from "./RiskAssign.vue?vue&type=script&lang=js"
export * from "./RiskAssign.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29e2f6ca",
  null
  
)

export default component.exports