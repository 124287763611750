<template>
  <div>
    <PageIntroduce
      :title="'风险整改'"
      :desc="'通过差距分析，逐项比对法规的条款要求，快速识别到产品法律风险'"
    />
    <PageTotalChart
      v-loading="!barSeriesArr.length"
      left-title="风险统计"
      :bar-series-arr="barSeriesArr"
      :backgrounds="['primary', 'danger', 'warning', 'light-warning', 'success']"
      :categories="['风险总数','严重风险数', '高风险数', '中风险数', '低风险数']"
    />
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          border
          fit
          highlight-current-row
          :data="tableData"
          stripe
          style="width: 100%"
          @sort-change="sortChange"
        >
          <el-table-column
            prop="riskType"
            sortable
            width="120"
            :show-overflow-tooltip="true"
            :sort-by="'risk_type'"
            label="风险类别"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskType'"
                :select-list.sync="selectList"
                :label="'风险类别'"
                :value.sync="listQuery.riskType"
                :dropdown-options="riskTypeOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="explanation"
            label="风险描述"
          >
            <template slot-scope="{row}">
              <TableTips :text="row.explanation" />
            </template>
          </el-table-column>
          <el-table-column
            prop="riskObjectName"
            :sort-by="'risk_object_name'"
            width="120"
            sortable
            label="关联对象"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskObject'"
                :select-list.sync="selectList"
                :label="'关联对象'"
                :value.sync="listQuery.riskObject"
                :dropdown-options="riskObjectOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="riskLevel"
            :sort-by="'risk_level'"
            sortable
            width="115"
            label="风险等级"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskLevel'"
                :select-list.sync="selectList"
                :label="'风险等级'"
                :value.sync="listQuery.riskLevel"
                :dropdown-options="levelOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              <b-badge
                class="d-flex justify-content-center"
                style="align-items: center"
                pill
                :variant="levelColorObj[row.riskLevel]"
              >
                {{ levelObj[row.riskLevel] }}
              </b-badge>
            </template>
          </el-table-column>
          <el-table-column
            prop="riskSourceName"
            :sort-by="'risk_source_name'"
            sortable
            :show-overflow-tooltip="true"
            width="115"
            label="风险来源"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskSource'"
                :select-list.sync="selectList"
                :label="'风险来源'"
                :value.sync="listQuery.riskSource"
                :dropdown-options="riskSourceOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            width="90"
            prop="createTime"
            label="产生时间"
          >
            <template slot-scope="{row}">
              {{ formatDateStr(row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="115"
            sortable
            prop="riskStatus"
            :sort-by="'risk_status'"
            label="风险状态"
          >
            <template slot="header">
              <TableHeaderFilterDropdown
                :key-str="'riskStatus'"
                :select-list.sync="selectList"
                :label="'风险状态'"
                :value.sync="listQuery.riskStatus"
                :dropdown-options="riskStatusOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              <div
                :style="row.riskStatus ==2 ? {color:'#0d40b2'} : {}"
                @click="overruleReasonDialogShow(row)"
              >
                {{ riskStatusObj[row.riskStatus] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="disposalAdvice"
            label="处置建议"
          >
            <template slot-scope="{row}">
              <TableTips :text="row.disposalAdvice" />
            </template>
          </el-table-column>
          <el-table-column
            prop="assignUserName"
            :show-overflow-tooltip="true"
            label="发布人员"
          />
          <!-- <el-table-column prop="disposalUserNames"
                           label="处理人员" /> -->
          <el-table-column
            prop="assignTime"
            width="90"
            label="指派时间"
          >
            <template slot-scope="{row}">
              {{ formatDateStr(row.assignTime) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="forecastFinishTime"
            width="90"
            label="完成时间"
          >
            <template slot-scope="{row}">
              {{ formatDateStr(row.forecastFinishTime) }}
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180"
          >
            <template slot-scope="{row}">
              <el-link
                v-if="readPerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="riskDetail(row.id)"
              >
                <el-tooltip
                  content="查看"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-document" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="row.riskStatus === 4 && row.auditPerm && confrimPerm"
                :underline="false"
                class="mr10"
                type="success"
                @click="confrimDialogShow(row)"
              >
                <el-tooltip
                  content="审核"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-finished" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="(row.riskStatus===1 || row.riskStatus===3 || row.riskStatus===5) && row.handlePerm && rejectPerm"
                :underline="false"
                class="mr10"
                type="danger"
                @click="rejectDialogShow(row)"
              >
                <el-tooltip
                  content="驳回"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-circle-close" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="(row.riskStatus===1 || row.riskStatus===3 || row.riskStatus===5) && row.handlePerm && runPerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="riskRunDialogShow(row)"
              >
                <el-tooltip
                  content="处理"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-coordinate" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="row.riskStatus!==4 && row.auditPerm && assginPerm"
                :underline="false"
                type="primary"
                @click="assignDialogShow(row)"
              >
                <el-tooltip
                  content="指派"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-thumb" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <PageQuery
          v-show="total>0"
          :total="total"
          :cache-name="'listQuery11'"
          :update-state="'UPDATE_LISTQUERY11'"
          :list-query.sync="listQuery"
          :init-list-query.sync="initListQuery"
          @pagination="getList"
        />
      </div>

    </b-card>
    <RiskConfrum
      ref="riskConfrum"
      @handleFilter="getList(1)"
    />
    <RiskReject
      ref="riskReject"
      @handleFilter="getList"
    />
    <RiskRun
      ref="riskRun"
      @handleFilter="getList"
    />
    <RiskAssign
      ref="riskAssign"
      @handleFilter="getList"
    />
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import PageTotalChart from '@/@core/components/page-total-chart/PageTotalChart.vue'
import { getUserData } from '@/auth/utils'
import {
  GetRiskDisposalListByPage,
  GetDisposalRiskCountSatisfaction,
  GetRiskManageDetail,
  GetAllRiskTypes,
  GetAllRiskSources,
  GetAllRiskObjects, RiskPage,
} from '@/api/risk/risk'
import { findBtnPerm, getBtnPerms, verifyBtnPerm } from '@core/utils/utils'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import RiskStatistics from '@/views/risk/components/RiskStatistics.vue'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import RiskConfrum from '@/views/risk/riskRectify/RiskConfrim.vue'
import RiskReject from '@/views/risk/riskRectify/RiskReject.vue'
import RiskRun from '@/views/risk/riskRectify/RiskRun.vue'
import RiskAssign from '@/views/risk/riskPerception/RiskAssign.vue'
import TableTips from '@core/components/table-tips/TableTips.vue'

export default {
  components: {
    pagination,
    FilterSelectList,
    TableHeaderFilterDropdown,
    RiskStatistics,
    PageIntroduce,
    PageTotalChart,
    RiskConfrum,
    RiskReject,
    RiskRun,
    RiskAssign,
    TableTips,
  },
  data() {
    const listQuery = {
      SearchStr: '',
      orderBy: {
        riskLevel: '',
        riskObject: '',
        riskSource: '',
        riskStatus: '',
        riskType: '',
      },
      page: 1,
      pageSize: 10,
      riskLevel: 0,
      riskObject: '',
      riskSource: '',
      riskStatus: 0,
      riskType: '',
    }
    return {
      pieLabels1: [],
      pieServise1: [],
      pieLabels2: [],
      pieServise2: [],
      pieLabels3: [],
      pieServise3: [],
      pieChartInit1: false,
      pieChartInit2: false,
      pieChartInit3: false,
      selectList: [],
      levelColorObj: {
        5: 'danger',
        4: 'warning',
        3: 'light-warning',
        2: 'success',
      },
      riskStatusObj: {
        1: '待处理',
        2: '驳回 ',
        3: '待整改',
        4: '待审核',
        5: '未通过审核',
      },
      levelObj: {
        2: '低',
        3: '中',
        4: '高',
        5: '严重',
      },
      riskStatusOptions: [
        { label: '待整改', value: 3 },
        { label: '待审核', value: 4 },
        { label: '未通过审核', value: 5 },
      ],
      levelOptions: [
        { label: '低', value: 2 },
        { label: '中', value: 3 },
        { label: '高', value: 4 },
        { label: '严重', value: 5 },
      ],
      rejectPerm: findBtnPerm('risk-rectify-2'),
      runPerm: findBtnPerm('risk-rectify-3'),
      readPerm: findBtnPerm('risk-rectify-4'),
      confrimPerm: findBtnPerm('risk-rectify-5'),
      assginPerm: findBtnPerm('risk-rectify-6'),
      loading: false,
      riskTotalInit: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      levels: [],
      products: [],
      sources: [],
      riskSourceOptions: [],
      riskObjectOptions: [],
      riskTypeOptions: [],
      riskTypes: [],
      actualName: '',
      barSeriesArr: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.rejectPerm = verifyBtnPerm(btnPerms, 'risk-rectify-2')
      this.runPerm = verifyBtnPerm(btnPerms, 'risk-rectify-3')
      this.readPerm = verifyBtnPerm(btnPerms, 'risk-rectify-4')
      this.confrimPerm = verifyBtnPerm(btnPerms, 'risk-rectify-5')
      this.assginPerm = verifyBtnPerm(btnPerms, 'risk-rectify-6')
    })
    this.getAllRiskTypes()
    this.getAllRiskSources()
    this.getAllRiskObjects()
    this.actualName = getUserData().id
    const cacheQuery = this.$store.state.pageQuery.listQuery11
    this.listQuery = { ...cacheQuery }
    this.getList()
    this.getDisposalRiskCountSatisfaction()
  },
  methods: {
    getAllRiskSources() {
      GetAllRiskSources().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskSourceOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    getAllRiskTypes() {
      GetAllRiskTypes().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskTypeOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    getAllRiskObjects() {
      GetAllRiskObjects().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskObjectOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    assignDialogShow(row) {
      GetRiskManageDetail({ id: Number(row.id) }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.$refs.riskAssign.dialogVisible = true
          this.$refs.riskAssign.form.riskManageIds = [resData.data.id]
          this.$refs.riskAssign.form.finishDate = resData.data.forecastFinishTime
          this.$refs.riskAssign.form.disposalUserIds = [...new Set(resData.data.disposalUsers.map(u => u.id))]
        }
      })
    },
    riskRunDialogShow(row) {
      this.$refs.riskRun.dialogVisible = true
      this.$refs.riskRun.form.riskManageId = row.id
    },
    rejectDialogShow(row) {
      this.$refs.riskReject.dialogVisible = true
      this.$refs.riskReject.form.riskManageId = row.id
    },
    confrimDialogShow(row) {
      this.$refs.riskConfrum.dialogVisible = true
      this.$refs.riskConfrum.form.riskManageId = row.id
    },
    // 风险等级占比
    getDisposalRiskCountSatisfaction() {
      this.barSeriesArr = []
      GetDisposalRiskCountSatisfaction().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.barSeriesArr = [
            resData.data.total,
            resData.data.seriousRiskCount,
            resData.data.heightRiskCount,
            resData.data.middleRiskCount,
            resData.data.lowRiskCount,
            // resData.data.veryLowRiskCount,
          ]
        }
      })
    },
    riskDetail(id) {
      this.$router.push({
        path: '/risk-rectifydetail',
        query: {
          id,
        },
      })
    },
    getList(flag) {
      this.loading = true
      this.listQuery.riskLevel = Number(this.listQuery.riskLevel)
      this.listQuery.riskStatus = Number(this.listQuery.riskStatus)
      RiskPage(2, this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
        this.riskTotalInit = true
        if (flag) {
          this.getDisposalRiskCountSatisfaction()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = { descending: 'DESC', ascending: 'ASC' }
      this.listQuery.orderField = column.column.sortBy
      this.listQuery.order = orderObj[order]
      this.getList()
    },
  },
}
</script>

<style>
.myNote {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.risk-statistics {
  display: flex;
  justify-content: space-evenly;
}
</style>
