<template>
    <el-dialog :visible.sync="dialogVisible"
               width="800px"
               :append-to-body="true"
               :modal-append-to-body="false"
               @close="dialogClose"
               @open="dialogOpen">
        <div slot="title">
            选择<span>【{{form.riskManageIds.length}}】</span>条风险进行指派处理
        </div>
        <el-form ref="form"
                 v-loading="loading"
                 :model="form"
                 label-width="120px">
            <el-form-item label="责任人"
                          prop="disposalUserIds"
                          :rules="[ { required: true, message: '用户不能为空', trigger: 'change' }]">
                <div class="flex-start">
                    <div class="mr10">已选择<font style="color: #0d40b2;font-size: 14px;margin: 0 5px">{{form.disposalUserIds.length}}</font>个
                    </div>
                    <el-button @click="personSelectDialogShow"
                               size="small"
                               type="primary"
                               icon="el-icon-plus">添加人员
                    </el-button>
                </div>
            </el-form-item>
            <el-form-item prop="finishDate"
                          :rules="[ { required: true, message: '完成时间不能为空', trigger: 'change' }]"
                          label="完成时间">
                <el-date-picker v-model="form.finishDate"
                                placement="bottom-start"
                                format="yyyy-MM-dd HH:ss:mm"
                                value-format="yyyy-MM-dd HH:ss:mm"
                                :picker-options="afterTodayPickerOptions"
                                type="datetime"
                                placeholder="选择完成时间"/>
            </el-form-item>
        </el-form>
        <div slot="footer"
             class="dialog-footer">
            <el-button @click="dialogVisible = false">
                取 消
            </el-button>
            <el-button type="primary"
                       @click="submitForm">
                提 交
            </el-button>
        </div>
        <PersonSelect :pUserIds.sync="form.disposalUserIds"
                      :multiple="true"
                      ref="personSelect"/>
    </el-dialog>
</template>

<script>
  import PersonSelect from '@core/components/person-select/PersonSelect'
  import { AssignRisk } from '@/api/risk/risk'
  import { error, success } from '@core/utils/utils'

  export default {
    components: { PersonSelect },
    data() {
      return {
        loading: false,
        dialogVisible: false,
        form: {
          disposalUserIds: [],
          finishDate: '',
          riskManageIds: []
        }
      }
    },
    methods: {
      personSelectDialogShow() {
        this.$refs.personSelect.dialogVisible = true
      },
      submitForm() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.loading = true
            AssignRisk(this.form)
              .then(res => {
                this.loading = false
                const resData = res.data
                if (resData.code === 0) {
                  success(resData.msg)
                  this.$emit('handleFilter')
                  this.dialogVisible = false
                } else {
                  error(resData.msg)
                }
              })
          } else {
            return false
          }
        })
      },
      dialogClose() {
        this.form = {
          disposalUserIds: [],
          finishDate: '',
          riskManageIds: []
        }
      },
      dialogOpen() {
      },
    }
  }
</script>

<style scoped>
</style>
